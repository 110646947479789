export class IbarService {
  public static async getIbarConfig(baseUrl: string): Promise<any> {
    try {
      const response = await fetch(
        baseUrl + "/api/v3/journals/components/ibar"
      );
      const ibarConfig = await response.json();
      return ibarConfig;
    } catch (e) {
      console.error(e);
      return [];
    }
  }
}
