<template>
  <header>
    <div class="navbar_wrapper">
      <Ibar v-bind="ibar" :show-submit-button="false" :about-us="[]" />
    </div>
  </header>

  <div class="container" :class="{ 'container--tac' : isTermsAndConditions}">
    <NuxtPage />
  </div>

  <footer>
    <div class="container_footer">
      <div class="footer_left">
        <span>&copy; {{ new Date().getFullYear() }} Frontiers Media S.A. All rights reserved</span>
        <span>
          <BLink :colorScheme="'blue'" size="sm" :inline="true" as="a" target="_blank" :to=privacyPolicyUrl>
            Privacy Policy
          </BLink>
          |
          <BLink :colorScheme="'blue'" size="sm" :inline="true" as="a" target="_blank" :to=termsAndConditionsUrl>
            Terms and Conditions
          </BLink>
        </span>
      </div>
      <span>Powered by <BLink :colorScheme="'blue'" size="sm" :inline="true" as="a" target="_blank" :to=loopUrl>Loop
        </BLink></span>
    </div>
  </footer>
</template>

<script setup lang="ts">
    import { NuxtPage } from "#components";
    import { BLink } from "@frontiers/brink-ui";
    import { Ibar } from "@frontiers-vue3/public-pages";
    import { useIbarStore } from "~/stores/ibarStore";
    import { useConfigurationStore } from "~/stores/configurationStore";
    const configurationStore = useConfigurationStore();
    const configuration = configurationStore.config;

    const loopUrl = configuration?.domainProperties.loopUrl;
    const baseUrl = configuration?.domainProperties.defaultWebsite;
    const privacyPolicyUrl = baseUrl + "/legal/privacy-policy";
    const termsAndConditionsUrl = baseUrl + "/legal/terms-and-conditions";
    const ibarStore = useIbarStore();
    const ibar = await ibarStore.fetchData(baseUrl);
    const route = useRoute();

    const isTermsAndConditions = route.path.includes("/legal/") || route.path.includes("termsandconditions");

</script>

<style lang="scss">
body {
  scroll-behavior: smooth;
}

#__nuxt {
  -webkit-font-smoothing: antialiased;
  justify-content: center;
  position: relative;
}

.navbar_wrapper {
  position: relative;
  left: 0;
  top: 0;
  min-height: 64px;
  /* the following style has been applied in the ibar because the shadow was missed with the scroll */
  /* box-shadow: 0 0 20px 2px lightgray; */
  z-index: 31;
}

.Ibar__wrapper {
  box-shadow: 0 0 20px 2px $grey30;
}

@media screen and (max-width: 1024px) {
  .navbar_wrapper {
    min-height: 48px;
  }
}

.footer_left {
  display: flex;
  gap: 64px;
}

.Ibar__link,
.Ibar__burger,
.Ibar__icon--search,
.Ibar__myFrontiers {
  display: none;
}

.logo {
  display: flex;
  margin: 0;
}
</style>
