import { IbarService } from "~/services/IbarService";
import { defineStore } from "pinia";

export const useIbarStore = defineStore("ibar", {
  state: () => ({}),
  getters: {},
  actions: {
    async fetchData(baseUrl: string): Promise<void> {
      try {
        const response = await IbarService.getIbarConfig(baseUrl);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  }
});
